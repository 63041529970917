import React from "react";
import PageAboutExperience from "../../../About/AboutExperience/PageAboutExperience";

const SecurityServiceExcellenceSection = () => {
  const experienceTitle =
    "Nearing Four Decades of Security Excellence Personified ";
  const experienceDescription =
    "Our extensive experience in the security domain and relentless pursuit towards quality-driven services have taken us into every nook and corner of India and there is no looking back!";
  const locationsLabel = "Locations";
  const employeesLabel = "Clients";
  const clientsLabel = "Taskforce";
  const locationsCount = "100+";
  const employeesCount = "900 +";
  const clientsCount = "30 K+";

  return (
    <>
      <PageAboutExperience
        experienceTitle={experienceTitle}
        experienceDescription={experienceDescription}
        locationsLabel={locationsLabel}
        employeesLabel={employeesLabel}
        clientsLabel={clientsLabel}
        locationsCount={locationsCount}
        employeesCount={employeesCount}
        clientsCount={clientsCount}
      />
    </>
  );
};

export default SecurityServiceExcellenceSection;
