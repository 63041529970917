import React from "react";
import "./CaseMgmtServiceOverviewSection.css";

const CaseMgmtServiceOverviewSection = () => {
  return (
    <>
      <div className="case_mgmt_overview_main_container">
        <div className="case_mgmt_overview_title">
          <p>OverView</p>
          <h3>Cash Management Services</h3>
        </div>
        <div className="case_mgmt_overview_content">
          <p>
            We at Checkmate understand precisely how cash is considered the oxygen
            of your business. Our services adhere to the Reserve Bank of India
            directives on cash handling and the customised need of banks in cash
            transportation, vaulting & processing. Our cash related services,
            like cash in transit, doorstep banking, cash vaulting , cash
            processing ,ATM and cash Recycler replenishment etc meet with global
            best practices . Geography no bound, our cash management services
            have been spread across remote areas as well. We are operating 1000+
            fleet of cash vans , with surveillance systems and 4000 trained CIT
            crew. Our support extends pan India to our valuable customers.
            <br />
            <br />
            For cash & bullion movement we have a dedicated 24X7 control room to
            monitor the cash van fitted with latest monitoring systems and tools
            to further manage and mitigate risk. For cash handling we operate
            with high end cash processing machines backed with strict procedures
            customised to rule out manual errors and enhance efficiency.
          </p>
        </div>
      </div>
    </>
  );
};

export default CaseMgmtServiceOverviewSection;
