import React, { useState } from "react";
import "../../assets/color/root_color.css";
import "./Footer.css";
import { Link, useLocation } from "react-router-dom";

import footer_section_location_white from "../../assets/icons/footer_section_location_white.svg";
import footer_section_location_green from "../../assets/icons/footer_section_location.svg";
import footer_section_calling_white from "../../assets/icons/footer_section_calling.svg";
import footer_section_calling_green from "../../assets/icons/footer_section_calling_green.svg";

import ul_li_white from "../../assets/icons/footer_section_ul_li_white_arrow.svg";
import footer_logo from "../../assets/images/footer_logo.png";

import facebook_bg_black_icon from "../../assets/icons/footer_section_facebook_bg_black.svg";
import instagram_bg_black_icon from "../../assets/icons/footer_section_instagram_bg_black.svg";
import youtube_bg_black_icon from "../../assets/icons/footer_section_youtube_bg_black.svg";
import twitter_bg_black_icon from "../../assets/icons/fooer_section_twitter_bg_black.svg";
import linkedln_bg_black_icon from "../../assets/icons/fooer_section_linkedln_bg_black.svg";

const Footer = () => {
  const location = useLocation();
  const [activeIcon, setActiveIcon] = useState(null);
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const openWhatsApp = (number) => {
    const url = `https://wa.me/${number}`;
    openInNewTab(url);
  };

  const handleIconClick = (url, iconName) => {
    window.open(url, "_blank");
    setActiveIcon(iconName);
  };
  const openGoogleMaps = () => {
    const officeAddress =
      "1111 - 1122 , 11th Floor , Block - 1B , 73 East Avenue ,  Bhailal Amin Marg  , Sarabhai Campus , Vadodara - 390023, Gujarat (India).";
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      officeAddress
    )}`;
    window.open(mapsUrl, "_blank");
  };
  const handleMouseEnter = (iconName) => {
    setHoveredIcon(iconName);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  return (
    <>
      <footer className="footer_section_main_container">
        <div className="footer-section">
          <p className="footer-section_header">Our Head Office</p>
          <ul>
            <li
              onClick={openGoogleMaps}
              className="footer_section1_content_location"
              onMouseEnter={() => handleMouseEnter("location")}
              onMouseLeave={handleMouseLeave}
            >
              {" "}
              {hoveredIcon === "location" ? (
                <img src={footer_section_location_green} alt="" />
              ) : (
                <img src={footer_section_location_white} alt="" />
              )}
              <span>
                1111 - 1122, 11th Floor,
                <br />
                Block - 1B, 73 East Avenue,
                <br />
                Bhailal Amin Marg, Sarabhai Campus, Vadodara - 390023,
                 Gujarat
                (India).
              </span>
            </li>
            <li
              className="footer_section1_content_calling"
              onClick={() => openWhatsApp("+91 265 4029999")}
              onMouseEnter={() => handleMouseEnter("calling")}
              onMouseLeave={handleMouseLeave}
            >
              {" "}
              {hoveredIcon === "calling" ? (
                <img src={footer_section_calling_green} alt="" />
              ) : (
                <img src={footer_section_calling_white} alt="" />
              )}
              <span>+91 265 4029999</span>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <p className="footer-section_header"></p>
          <ul>
            <li
              className={
                location.pathname === "/"
                  ? "footer-section_header_ul_li_main active"
                  : "footer-section_header_ul_li_main"
              }
              onClick={() => (window.location.href = "/")}
            >
              Home
            </li>
            <li
              className={
                location.pathname === "/about-us"
                  ? "footer-section_header_ul_li active"
                  : "footer-section_header_ul_li"
              }
              onClick={() => (window.location.href = "/about-us")}
            >
              About Us
            </li>
            <li
              className={
                location.pathname === "/industries"
                  ? "footer-section_header_ul_li active"
                  : "footer-section_header_ul_li"
              }
              onClick={() => (window.location.href = "/industries")}
            >
              Industries
            </li>
            <li
              className={
                location.pathname === "/contact-us"
                  ? "footer-section_header_ul_li active"
                  : "footer-section_header_ul_li"
              }
              onClick={() => (window.location.href = "/contact-us")}
            >
              Contact Us 
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <p className="footer-section_header">Our Services</p>
          <ul>
            <li
              className={
                location.pathname === "/services/security-service"
                  ? "footer-section_header_ul_li_main active"
                  : "footer-section_header_ul_li_main"
              }
              onClick={() =>
                (window.location.href = "/services/security-service")
              }
            >
              <img src={ul_li_white} alt="" />
              <img src={ul_li_white} alt="" />
              <span> Security Services</span>
            </li>
            <li
              className={
                location.pathname === "/services/facility-management-service"
                  ? "footer-section_header_ul_li_main active"
                  : "footer-section_header_ul_li_main"
              }
              onClick={() =>
                (window.location.href = "/services/facility-management-service")
              }
            >
              <img src={ul_li_white} alt="" />
              <img src={ul_li_white} alt="" />
              <span> Facility Management Services</span>
            </li>
            <li
              className={
                location.pathname === "/services/fire-safety-service"
                  ? "footer-section_header_ul_li_main active"
                  : "footer-section_header_ul_li_main"
              }
              onClick={() =>
                (window.location.href = "/services/fire-safety-service")
              }
            >
              <img src={ul_li_white} alt="" />
              <img src={ul_li_white} alt="" />
              <span> Fire & Safety Services</span>
            </li>
            <li
              className={
                location.pathname === "/services/electronic-security-service"
                  ? "footer-section_header_ul_li_main active"
                  : "footer-section_header_ul_li_main"
              }
              onClick={() =>
                (window.location.href = "/services/electronic-security-service")
              }
            >
              <img src={ul_li_white} alt="" />
              <img src={ul_li_white} alt="" />
              <span> Electronic Security Services</span>
            </li>
            <li
              className={
                location.pathname === "/services/cash-management-service"
                  ? "footer-section_header_ul_li_main active"
                  : "footer-section_header_ul_li_main"
              }
              onClick={() =>
                (window.location.href = "/services/cash-management-service")
              }
            >
              <img src={ul_li_white} alt="" />
              <img src={ul_li_white} alt="" />
              <span> Cash Management Services</span>
            </li>
            <li
              className={
                location.pathname === "/services/event-management-service"
                  ? "footer-section_header_ul_li_main active"
                  : "footer-section_header_ul_li_main"
              }
              onClick={() =>
                (window.location.href = "/services/event-management-service")
              }
            >
              <img src={ul_li_white} alt="" />
              <img src={ul_li_white} alt="" />
              <span> Event Management Services</span>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <p className="footer-section_header">Connect with Us</p>
          <div className="footer-section_icons">
            <div
              onClick={() =>
                handleIconClick(
                  "https://twitter.com/checkmateestd89?s=21&t=05qgsBzcSUiRfWiWUK5c2A",
                  "twitter"
                )
              }
              className={`footer-section_icons_box2 ${
                activeIcon === "twitter" ? "active" : ""
              }`}
            >
              <img src={twitter_bg_black_icon} alt="twitter_bg_icon" />
            </div>

            <div
              onClick={() =>
                handleIconClick(
                  "https://www.youtube.com/yourprofile",
                  "youtube"
                )
              }
              className={`footer-section_icons_box4 ${
                activeIcon === "youtube" ? "active" : ""
              }`}
            >
              <img src={youtube_bg_black_icon} alt="youtube_bg_icon" />
            </div>

            <div
              onClick={() =>
                handleIconClick(
                  "https://www.linkedin.com/company/checkmate-estd-1988/",
                  "linkedIn"
                )
              }
              className={`footer-section_icons_box5 ${
                activeIcon === "linkedIn" ? "active" : ""
              }`}
            >
              <img src={linkedln_bg_black_icon} alt="Linkedln_bg_icon" />
            </div>
          </div>
          <Link to="/">
            <img src={footer_logo} alt="Footer Logo" className="footer_logo" />
          </Link>
        </div>
      </footer>
      <div className="footer_copyright_section">
        <hr className="footer_section_horizonatalline" />
        <div className="footer_secion_copyright_content">
          Copyrights 2024 - Checkmate Group. All rights reserved.
        </div>
      </div>
    </>
  );
};

export default Footer;
