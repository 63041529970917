import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./Header.css";
import { IoCallOutline, IoMailOutline } from "react-icons/io5";

import facebook_icon from "../../assets/icons/facebook.svg";
import twitter_icon from "../../assets/icons/fooer_section_twitter_bg_white.svg";
import instagram_icon from "../../assets/icons/instagram.svg";
import youtube_icon from "../../assets/icons/youtube.svg";
import LinkedIn_icon from "../../assets/icons/LinkedIn_icon.svg";
import calling_icon_white from "../../assets/icons/calling.svg";
import mail_icon_white from "../../assets/icons/mail.svg";
import calling_icon_green from "../../assets/icons/footer_section_calling_green.svg";
import mail_icon_green from "../../assets/icons/mail_icon_green.svg";

import navbar_logo from "../../assets/images/navbar_logo.png";
import Hamburger from "../../pages/Home/HamBurger/Hamburger";

import security_service_icon from "../../assets/icons/service_section_popup_security_service.svg";
import facility_meng_icon from "../../assets/icons/service_section_popup_facility_man.svg";
import fire_safety_iocn from "../../assets/icons/service_section_popup_firesafety.svg";
import electronic_security_icon from "../../assets/icons/service_section_popup_electronic_security.svg";
import cash_meng from "../../assets/icons/service_section_popup_cash_man.svg";
import event_meng from "../../assets/icons/service_section_popup_event_man.svg";
import flag from "../../assets/images/flag.png";

const Header = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const whatsappNumber = "+91 265 4029999";
  const emailAddress = "contact@checkmateservices.com";
  const whatsappUrl = `https://wa.me/${whatsappNumber.replace(/\s+/g, "")}`;
  const mailtoUrl = `mailto:${emailAddress}`;

  const toggleHamburger = () => setHamburgerOpen(!hamburgerOpen);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
    if (!isMenuVisible) setShowMenu(false);
  };

  const closeMenu = () => {
    setHamburgerOpen(false);
    setIsMenuVisible(false);
  };

  const closeMegaMenu = () => {
    setShowMenu(false);
  };

  const handleDropdownClick = (e, dropdownId) => {
    e.preventDefault();
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div className="header">
        <div className="topbar">
          <div className="topbar-left">
            <div className="topbar-left-social">
              {/* <NavLink
                to="https://facebook.com"
                target="_blank"
                className="facebook_icon"
              >
                <img src={facebook_icon} alt="Facebook" />
              </NavLink> */}
              <NavLink
                to="https://twitter.com/checkmateestd89?s=21&t=05qgsBzcSUiRfWiWUK5c2A"
                target="_blank"
                className="twitter_icon"
              >
                <img src={twitter_icon} alt="Twitter" />
              </NavLink>

              {/* <NavLink
                to="https://instagram.com"
                target="_blank"
                className="instagram_icon"
              >
                <img src={instagram_icon} alt="Instagram" />
              </NavLink> */}
              <NavLink
                to="https://www.youtube.com/@checkmatenetwork4065"
                target="_blank"
                className="youtube_icon"
              >
                <img src={youtube_icon} alt="YouTube" />
              </NavLink>

              <NavLink
                to="https://www.linkedin.com/company/checkmate-estd-1988/"
                target="_blank"
                className="linkedIn_icon"
              >
                <img src={LinkedIn_icon} alt="LinkedIn" />
              </NavLink>
            </div>
          </div>
          <div className="topbar-right">
            <NavLink
              to={whatsappUrl}
              target="_blank"
              className="topbar-right-call"
            >
              <div className="icon-wrapper">
                <img
                  src={calling_icon_white}
                  alt="Call"
                  className="default-icon"
                />
                <img
                  src={calling_icon_green}
                  alt="Call"
                  className="hover-icon"
                />
                {/* <IoCallOutline className="hover-icon" /> */}
              </div>
              <span>{whatsappNumber}</span>
            </NavLink>

            <NavLink
              to={mailtoUrl}
              target="_blank"
              className="topbar-right-mail"
            >
              <div className="icon-wrapper">
                <img
                  src={mail_icon_white}
                  alt="Mail"
                  className="default-icon"
                />
                <img src={mail_icon_green} alt="Mail" className="hover-icon" />
                {/* <IoMailOutline className="hover-icon" /> */}
              </div>
              <span>{emailAddress}</span>
            </NavLink>
          </div>
        </div>

        <div className="navbar_container">
          <div className="navbar">
            <div className="navbar-left">
              <Link to="/">
                <img src={navbar_logo} alt="Navbar_CheckMate_logo" />
              </Link>
            </div>
            <div className="navbar-right">
              <ul className="hamburgerOpen">
                <li
                  className={`nav-item home_section ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                    Home
                  </Link>
                </li>
                <li
                  className={`nav-item about_section ${
                    location.pathname.startsWith("/about-us") ? "active" : ""
                  }`}
                >
                  <Link to="/about-us" onClick={() => window.scrollTo(0, 0)}>
                    About Us
                  </Link>
                </li>

                <li
                  className={`nav-item services_section ${
                    location.pathname.startsWith("/services") ? "active" : ""
                  }`}
                  onMouseEnter={() => setShowMenu(true)}
                  onMouseLeave={() => setShowMenu(false)}
                >
                  Services
                  {showMenu && (
                    <MegaMenu closeMegaMenu={() => setShowMenu(false)} />
                  )}
                </li>

                <li
                  className={`nav-item industries_section ${
                    location.pathname === "/industries" ? "active" : ""
                  }`}
                >
                  <Link to="/industries" onClick={() => window.scrollTo(0, 0)}>
                    Industries
                  </Link>
                </li>
              </ul>
          
              <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)}>
                <button className="contactus_btn" type="button">
                  Contact Us
                </button>
              </Link>
              <img src={flag} alt="indian flag"  style={{width:"7%"}}/>
            </div>
            
          </div>
        </div>

        <div className="main-responsive-navbar-main">
          <header>
            <div className="res">
              <nav className={hamburgerOpen ? "nav-open" : ""}>
                <div className="logo">
                  <Link to="/">
                    <img src={navbar_logo} alt="Logo" />
                  </Link>
                </div>
                <div
                  className="menu-toggle"
                  id="mobile-menu"
                  onClick={toggleMenu}
                >
                  <div className="hamburger" onClick={toggleHamburger}>
                    <Hamburger isOpen={hamburgerOpen} />
                  </div>
                </div>

                <ul className={`nav ${isMenuVisible ? "showing" : ""}`}>
                  <li
                    className={`nav-item ${
                      location.pathname === "/" ? "active" : ""
                    }`}
                  >
                    <Link to="/" onClick={closeMenu}>
                      Home
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname.startsWith("/about-us") ? "active" : ""
                    }`}
                  >
                    <Link to="/about-us" onClick={closeMenu}>
                      About Us
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname.startsWith("/service") ? "active" : ""
                    }`}
                  >
                    <Link
                      to=""
                      className={`menu-item ${
                        activeDropdown === 2 ? "active" : ""
                      }`}
                      onClick={(e) => handleDropdownClick(e, 2)}
                    >
                      Services
                      <span
                        className={`arrow ${
                          activeDropdown === 2 ? "rotate" : ""
                        }`}
                      ></span>
                    </Link>
                    <ul
                      className={`dropdown ${
                        activeDropdown === 2 ? "show" : ""
                      }`}
                    >
                      <li>
                        <Link
                          to="/services/security-service"
                          onClick={closeMenu}
                        >
                          {" "}
                          Security Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/facility-management-service"
                          onClick={closeMenu}
                        >
                          {" "}
                          Facility Management Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/fire-safety-service"
                          onClick={closeMenu}
                        >
                          {" "}
                          Fire & Safety Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/electronic-security-service"
                          onClick={closeMenu}
                        >
                          Electronic Security Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/cash-management-service"
                          onClick={closeMenu}
                        >
                          Cash Management Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/event-management-service"
                          onClick={closeMenu}
                        >
                          Event Management Services
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname === "/industries" ? "active" : ""
                    }`}
                  >
                    <Link onClick={closeMenu} to="/industries">
                      Industries
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      location.pathname === "/contact-us" ? "active" : ""
                    }`}
                  >
                    <Link to="/contact-us" onClick={closeMenu}>
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </header>
        </div>
      </div>
    </>
  );
};

export default Header;

// MegaMenu Code :

const MegaMenu = ({ closeMegaMenu }) => {
  const location = useLocation();
  const [activeService, setActiveService] = useState("Security Services");

  const isActive = (path) => location.pathname === path;
  const services = [
    {
      name: "Security Services",
      path: "/services/security-service",
      icon: security_service_icon,
    },
    {
      name: "Facility Management Services",
      path: "/services/facility-management-service",
      icon: facility_meng_icon,
    },
    {
      name: "Fire & Safety Services",
      path: "/services/fire-safety-service",
      icon: fire_safety_iocn,
    },
    {
      name: "Electronic Security Services",
      path: "/services/electronic-security-service",
      icon: electronic_security_icon,
    },
    {
      name: "Cash Management Services",
      path: "/services/cash-management-service",
      icon: cash_meng,
    },
    {
      name: "Event Management Services",
      path: "/services/event-management-service",
      icon: event_meng,
    },
  ];

  const serviceDetails = {
    "Security Services": [
      {
        name: "Manned Guarding",
        path: "/services/security-service",
      },
      {
        name: "Security Audits, Training & Consultancy",
        path: "/services/security-service",
      },
      {
        name: "Antecedent Verification",
        path: "/services/security-service",
      },
      {
        name: "Tech Integrated Security",
        path: "/services/security-service",
      },
      {
        name: "Risk Assessment & Threat Analysis",
        path: "/services/security-service",
      },
      {
        name: "Remote Surveillance",
        path: "/services/security-service",
      },
      {
        name: "Integrated Risk Management Solutions",
        path: "/services/security-service",
      },
    ],

    "Facility Management Services": [
      {
        name: "General Housekeeping & Mechanized Cleaning",
        path: "/services/facility-management-service",
      },
      {
        name: "Building Maintenance & Management",
        path: "/services/facility-management-service",
      },
      {
        name: "Engineering/Electrical Maintenance",
        path: "/services/facility-management-service",
      },
      {
        name: "Pest Control",
        path: "/services/facility-management-service",
      },
      {
        name: "Waste Management",
        path: "/services/facility-management-service",
      },
      {
        name: "Landscaping & Horticulture",
        path: "/services/facility-management-service",
      },
      {
        name: "Front-Facade Cleaning & High-Rise Glass Cleaning",
        path: "/services/facility-management-service",
      },
    ],

    "Fire & Safety Services": [
      {
        name: "Fire Prevention",
        path: "/services/fire-safety-service",
      },
      {
        name: "Crisis Management",
        path: "/services/fire-safety-service",
      },
      {
        name: "Hazardous Materials Response",
        path: "/services/fire-safety-service",
      },
      {
        name: "Customized Training",
        path: "/services/fire-safety-service",
      },
      {
        name: "Disaster Management",
        path: "/services/fire-safety-service",
      },
      {
        name: "Fire Risk Mitigation",
        path: "/services/fire-safety-service",
      },
      {
        name: "Combustion Safeguarding",
        path: "/services/fire-safety-service",
      },
    ],

    "Electronic Security Services": [
      {
        name: "CCTV Surveillance System",
        path: "/services/electronic-security-service",
      },
      {
        name: "Access Control System",
        path: "/services/electronic-security-service",
      },
      {
        name: "Network Infrastructure",
        path: "/services/electronic-security-service",
      },
      {
        name: "Intrusion Detection System",
        path: "/services/electronic-security-service",
      },
      {
        name: "Perimeter Security Solution",
        path: "/services/electronic-security-service",
      },
      {
        name: "Premium Access Management Solutions",
        path: "/services/electronic-security-service",
      },
    ],

    "Cash Management Services": [
      {
        name: "Cash in Transit & Logistics",
        path: "/services/cash-management-service",
      },
      {
        name: "Cash Processing",
        path: "/services/cash-management-service",
      },
      {
        name: "Cash Counters",
        path: "/services/cash-management-service",
      },
      {
        name: "Vaulting & Custom Clearance",
        path: "/services/cash-management-service",
      },
      {
        name: "Atm Cassete Change To Atm Replenishment",
        path: "/services/cash-management-service",
      },
      {
        name: "Cash Recyclers",
        path: "/services/cash-management-service",
      },
    ],

    "Event Management Services": [
      {
        name: "Pre-Event Planning & Coordination",
        path: "/services/event-management-service",
      },
      {
        name: "Identify Consequences",
        path: "/services/event-management-service",
      },
      {
        name: "Identify Security Threats",
        path: "/services/event-management-service",
      },
      {
        name: "Prioritize Hazardous Scenarios Associated with Threats",
        path: "/services/event-management-service",
      },
      {
        name: "Establish the role of Technology in the Event",
        path: "/services/event-management-service",
      },
      {
        name: "Review Security Measures & Rehearse / Review Plans",
        path: "/services/event-management-service",
      },
      {
        name: "Pre And Post Fire Safety Audits ",
        path: "/services/event-management-service",
      },
      {
        name: "Pre And Post Electrical Safety Audits ",
        path: "/services/event-management-service",
      },
    ],
  };

  const serviceDetails1 = {
    "Security Services": [
      {
        name: "Strategic Security Oversight",
        path: "/services/security-service",
      },
      {
        name: " Holistic Security Solutions",
        path: "/services/security-service",
      },
      {
        name: "Security Vulnerability Assessments",
        path: "/services/security-service",
      },
      {
        name: "Executive Protection and Concierge Security",
        path: "/services/security-service",
      },
      {
        name: "Critical Asset Protection Protocols",
        path: "/services/security-service",
      },
      {
        name: " HNI Security Customisation",
        path: "/services/security-service",
      },
      {
        name: " Travel Protocol Management",
        path: "/services/security-service",
      },
    ],

    "Facility Management Services": [
      {
        name: "Front Desk Management",
        path: "/services/facility-management-service",
      },
      {
        name: "Contractual Staffing & Drivers",
        path: "/services/facility-management-service",
      },
      {
        name: "Specialized Disinfection and Sanitization Protocols",
        path: "/services/facility-management-service",
      },
      {
        name: "Health & Safety Compliance Management",
        path: "/services/facility-management-service",
      },
      {
        name: "Automated Hygiene Solutions",
        path: "/services/facility-management-service",
      },
      {
        name: "Sustainable Waste Minimization & Recycling Programs ",
        path: "/services/facility-management-service",
      },
      {
        name: "Facility Stewardship",
        path: "/services/facility-management-service",
      },
    ],

    "Fire & Safety Services": [
      {
        name: "Tactical Retrieval",
        path: "/services/fire-safety-service",
      },
      {
        name: "Tailored Safety Training",
        path: "/services/fire-safety-service",
      },
      {
        name: "Fire And Safety Audits ",
        path: "/services/fire-safety-service",
      },
      {
        name: "Emergency Egress Solutions",
        path: "/services/fire-safety-service",
      },
      {
        name: "Operational Safety Framework",
        path: "/services/fire-safety-service",
      },
      {
        name: "Industrial Safety Optimization",
        path: "/services/fire-safety-service",
      },
      {
        name: "Training on Real Fuel - Real Fire ",
        path: "/services/fire-safety-service",
      },
    ],

    "Electronic Security Services": [
      {
        name: "High-Security Intrusion Prevention",
        path: "/services/electronic-security-service",
      },
      {
        name: "State-of-the-Art Alert Mechanisms",
        path: "/services/electronic-security-service",
      },
      {
        name: "High-Caliber Remote Surveillance Services",
        path: "/services/electronic-security-service",
      },
      {
        name: "High-Fidelity Biometric Authentication Systems",
        path: "/services/electronic-security-service",
      },
      {
        name: "Encrypted Communication Networks",
        path: "/services/electronic-security-service",
      },
      {
        name: "Drone surveillance system ",
        path: "/services/electronic-security-service",
      },
    ],

    "Cash Management Services": [
      {
        name: "Bullion Transportation ,Vaulting & Clearance",
        path: "/services/cash-management-service",
      },
      {
        name: "High-Security Cash Vaulting",
        path: "/services/cash-management-service",
      },
      {
        name: "Door Step Banking",
        path: "/services/cash-management-service",
      },
      {
        name: "Cheque Handling And Processing",
        path: "/services/cash-management-service",
      },
      {
        name: "Cash Processing Centers",
        path: "/services/cash-management-service",
      },
    ],

    "Event Management Services": [
      {
        name: "Crisis Management & Evacuation",
        path: "/services/event-management-service",
      },
      {
        name: "Strategic Security Solutions",
        path: "/services/event-management-service",
      },
      {
        name: "Premier Protective Services",
        path: "/services/event-management-service",
      },
      {
        name: "Comprehensive Risk Mitigation",
        path: "/services/event-management-service",
      },
      {
        name: "Advanced Threat Assessment",
        path: "/services/event-management-service",
      },
      {
        name: "Discreet Protective Services",
        path: "/services/event-management-service",
      },
      {
        name: "Pre And Post Housekeeping Services",
        path: "/services/event-management-service",
      },
      {
        name: "Providing NCVT Qualified Fire Officers",
        path: "/services/event-management-service",
      },
    ],
  };

  // Set active service based on path when the page loads
  useEffect(() => {
    const currentService = services.find((service) => isActive(service.path));
    if (currentService) {
      setActiveService(currentService.name);
    }
  }, [location.pathname]);

  return (
    <div className="megamenu_main_container">
      <div className="megamenu_main_layout">
        {/* Left Side with Services */}
        <div className="megamenu_left_side">
          {services.map((service) => (
            <Link
              key={service.name}
              to={service.path}
              onClick={closeMegaMenu}
              onMouseEnter={() => setActiveService(service.name)}
              style={{
                color: isActive(service.path)
                  ? "var(--TopBar-Dark-Green)"
                  : "black",
                display: "flex",
                gap: "30px",
              }}
            >
              <img
                src={service.icon}
                alt={service.name}
                className="service_icon"
                style={{ width: "30px" }}
              />
              <p style={{ paddingTop: "8px" }}>{service.name}</p>
            </Link>
          ))}
        </div>

        {/* Vertical Line */}
        <div className="vertical_line"></div>

        {/* Right Side with Corresponding UL */}
        <div className="megamenu_right_side">
          <div className="megamenu_right_side_1">
            {activeService && (
              <ul>
                {serviceDetails[activeService].map((item, index) => (
                  <li className="megamenu_right_side_ul_li" key={index}>
                    <Link to={item.path} onClick={closeMegaMenu}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="megamenu_right_side_2">
            {activeService && (
              <ul>
                {serviceDetails1[activeService].map((item, index) => (
                  <li className="megamenu_right_side_ul_li" key={index}>
                    <Link to={item.path} onClick={closeMegaMenu}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
