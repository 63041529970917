import React from "react";
import "./SecurityServiceOverviewSection.css";

const SecurityServiceOverviewSection = () => {
  return (
    <>
      <div className="service_overview_main_container">
        <div className="service_overview_tittle">
          <p>OverView</p>
          <h3>Security Services</h3>
        </div>
        <div className="service_overview_content">
          <p>
            With a stringent focus on prioritizing safety and security,
            providing the top-in-line security services remains our primary
            forte over the years. As one of the leading guarding companies in
            India, we at Checkmate, specialize in provision of security services,
            solutions and products to a vast clientele across India. Our robust
            and tailored security services and solutions are well-designed
            keeping in mind your requirements and we ensure that we take up the
            complete responsibility of offering you a highly secure environment,
            so that it is not your worry anymore.
            <br />
            <br />
            We understand the importance of feeling physically and emotionally
            secure and that is where our skilled and well-trained taskforce
            ensures a round-the-clock and vigilant safekeeping, to minimalize
            your stress levels. We leave no stone unturned in setting up a
            highly surveillant environment which has a quick response time and
            advocates a sense of order, be it any place – your home, office,
            event or any location.
          </p>
        </div>
      </div>
    </>
  );
};

export default SecurityServiceOverviewSection;
