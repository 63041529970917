import React, { useState, useRef } from "react";
import "./EventMgmtOurServiceSection.css";
import home_chess from "../../../../assets/icons/home_chess.svg";

import up_icon from "../../../../assets/icons/our_service_section_left_side_up_icon.svg";
import down_icon from "../../../../assets/icons/our_service_section_left_side_down_icon.svg";

const services = {
  "Pre-Event Planning & Coordination": {
    title: "Pre-Event Planning & Coordination",
    content: (
      <>
        <p>
          We believe that proper planning in advance and thorough coordination
          can lead to a successful event implementation. Selecting the venue in
          advance, making plans, laying down roles and responsibilities,
          preparing a detailed timeline, finalizing event manpower planning
          along with post wise briefing done – these are some of the pre-event
          activities that we focus on.
        </p>
      </>
    ),
  },
  "Identify Consequences": {
    title: "Identify Consequences",
    content: (
      <>
        <p>
          However much you try, there are chances of any unwanted situation to
          occur and create a mess. As proactive event managers, we attempt to
          identify consequences well in advance and create a risk mitigation
          strategy just in case those events happen. This helps us in better
          preparedness and relaxed execution at the end moment.
        </p>
      </>
    ),
  },
  "Identify Security Threats": {
    title: "Identify Security Threats",
    content: (
      <>
        <p>
          Security, being our primary forte, remains at the core, even as we
          plan events. Our security taskforce ensures complete scrutiny and
          assessment of all types of security protocols being followed. We also
          ensure a sharp eye on any type of malicious activity happening around
          and if so, ways and means of handling them.
        </p>
      </>
    ),
  },
  "Prioritize Hazardous Scenarios Associated with Threats": {
    title: "Prioritize Hazardous Scenarios Associated with Threats",
    content: (
      <>
        <p>
          Along with security, protecting the event venue against hazardous
          scenarios is equally important. And that is what we ensure as we get
          the event venue ready for the function. We proactively, visualize what
          could be the possible hazards that could occur and thereby, stay
          well-prepared for the same. This helps us in guaranteeing security in
          front of our clients and making them enjoy a relaxed function.
        </p>
      </>
    ),
  },
  "Establish the role of Technology in the Event": {
    title: "Establish the role of Technology in the Event",
    content: (
      <>
        <p>
          We are friends with technology and enabling modern-day technology
          advancements is something we look up to. Even, while we manage and
          execute events, we ensure usage of modern-day technology equipment and
          software solutions so that our work is faster, accurate and efficient.
          As event planners, it saves us on time, efforts and costs. It also
          ensures our clients are satisfied and engaged.
        </p>
      </>
    ),
  },
  "Review Security Measures & Rehearse / Review Plans": {
    title: "Review Security Measures & Rehearse / Review Plans",
    content: (
      <>
        <p>
          Our security taskforce keeps looking for all the implemented security
          measures to ensure there is no loophole. We also ensure that we stick
          to the latest available measure in the market so that we can give our
          clients the best of results. As a collaborative process, we keep
          rehearsing our security protocols to see if they work effectively or
          need enhancement.
        </p>
      </>
    ),
  },
  "Crisis Management & Evacuation": {
    title: "Crisis Management & Evacuation",
    content: (
      <>
        <p>
          As a part of proactive risk management, we ensure complete readiness
          to handling any type of crisis and evacuation strategies if needed.
          Each event is individualistic and demands a different way of managing
          crisis. Our expert event planners go by the nature of the event and
          keep a crisis plan readily executable.
        </p>
      </>
    ),
  },
  "Strategic Security Solutions": {
    title: "Strategic Security Solutions",
    content: (
      <>
        <p>
          Right from assessing risks in events, selecting the best of security
          staff till operational execution of the same, we offer a complete
          package of strategic security solutions that can encompass every nook
          and corner of the event that you are engaged in. We leave no
          responsibility on your head and take up the entire onus of managing
          the event.
        </p>
      </>
    ),
  },
  "Premier Protective Services": {
    title: "Premier Protective Services",
    content: (
      <>
        <p>
          While managing any event, there could be requirements of offering
          customized protective services to a selected few. We indulge in
          offering a thorough security setup to those chosen with best quality
          taskforce, secure environment and round-the-clock scrutiny. Our
          security taskforce have good backgrounds like military or para medical
          or law enforcement agencies.
        </p>
      </>
    ),
  },

  "Comprehensive Risk Mitigation": {
    title: "Comprehensive Risk Mitigation",
    content: (
      <>
        <p>
          One important area of having a smooth event execution is planning and
          mitigating risks. And that is what forms the core of our event
          management strategy. Our experienced event planners prepared a
          detailed risks assessment and mitigation plan so that no area is left
          untouched. This does call for efforts and time but leads to peace of
          mind and smooth execution later.
        </p>
      </>
    ),
  },
  "Advanced Threat Assessment": {
    title: "Advanced Threat Assessment",
    content: (
      <>
        <p>
          Advanced threat assessment goes a step further and incorporates
          advanced threat protection and analytics, digital threat training and
          management, prevention and execution. It goes a layer ahead and plans
          in such a way that it foresees the future and executes actions that
          will offer a smooth and secure implementation.
        </p>
      </>
    ),
  },
  "Discreet Protective Services": {
    title: "Discreet Protective Services",
    content: (
      <>
        <p>
          We offer discreet protective services that includes offering all types
          of protection but without producing much attention. It could include
          close aid protection, protective surveillance, private security,
          covert protection, workplace security and much more. This model is
          used by a selected few who need security services but not being
          noticed by all. It is more like an covert operation.
        </p>
      </>
    ),
  },
  "Pre And Post Fire Safety Audits": {
    title: "Pre And Post Fire Safety Audits",
    content: (
      <>
        <p>
          We offer pre audits and post audits for fire safety, to find out the
          possible fire hazards or actual events that have happened. Our
          compliance with fire safety rules and regulations is stringent and we
          understand the complete scenario by analysing the goal of the audit.
          Our auditors are work complacent in these arenas and hence offer
          complete depth into it.
        </p>
      </>
    ),
  },
  "Pre And Post Electrical Safety Audits": {
    title: "Pre And Post Electrical Safety Audits",
    content: (
      <>
        <p>
          Before and after the event, we provide audit services that checks the
          complete electrical safety mechanism. It finds out the possible risk
          areas that could come up with the events. Our audits comply with legal
          requirements for the electrical systems that are involved with each of
          their equipment associated with the systems.
        </p>
      </>
    ),
  },
  "Pre And Post Housekeeping Services": {
    title: "Pre And Post Housekeeping Services",
    content: (
      <>
        <p>
          Our perfect housekeeping services work hard before the event and after
          the event. Our pre-event services include complete dusting of all
          surfaces, mopping, vacuum cleaning, emptying bins, cleaning windows,
          housekeeping supplies etc. Our post-even services include trash
          removal, deep cleaning of wash areas, sanitation, rearranging all
          furniture to original form etc.
        </p>
      </>
    ),
  },
  "Providing NCVT Qualified Fire Officers": {
    title: "Providing NCVT Qualified Fire Officers",
    content: (
      <>
        <p>
          Our fire officers get NCVT certificates that can help them provide
          high quality fire and industrial safety services. Our own self
          training programme takes care of detailed orientation, real-life
          scenarios and actual onsite experience and drills that can make them
          completely ready to face any kind of situation. We have a huge
          taskforce that is spread all over the country offering the
          best-in-class fire safety measures.
        </p>
      </>
    ),
  },
};

const EventMgmtOurServiceSection = () => {
  const [selectedService, setSelectedService] = useState(
    "Pre-Event Planning & Coordination"
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const menuRef = useRef(null);
  const serviceRefs = useRef({});

  const handleMenuClick = (service) => {
    setSelectedService(service);
    setDropdownOpen(false);
    setShowContent(false);
    setTimeout(() => setShowContent(true), 5000);

    const rightSideService = document.querySelector(
      ".event_mgmt_our_service_right_side_services"
    );

    if (rightSideService) {
      const topPosition =
        rightSideService.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerHeight / 2 - rightSideService.offsetHeight / 2;
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollMenuUp = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex > 0) {
      const prevService = Object.keys(services)[currentIndex - 1];
      setSelectedService(prevService);
      serviceRefs.current[prevService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const scrollMenuDown = () => {
    const currentIndex = Object.keys(services).indexOf(selectedService);
    if (currentIndex < Object.keys(services).length - 1) {
      const nextService = Object.keys(services)[currentIndex + 1];
      setSelectedService(nextService);
      serviceRefs.current[nextService].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  return (
    <div className="event_mgmt_our_service_main_container">
      <div className="event_mgmt_our_service_title">
        <p>Our</p>
        <h3>Event Management Services</h3>
      </div>
      <div className="event_mgmt_our_service_menu_bar">
        <div className="event_mgmt_our_service_left_side_menu">
          <div className="dropdown_menu_container">
            <div
              className="event_mgmt_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <button
              className="dropdown_button"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {services[selectedService].title}
            </button>

            <div
              className="event_mgmt_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div>

          <div className="event_mgmt_our_service_grid_container">
            {Object.keys(services).map((service) => (
              <div
                key={service}
                className={`service_item ${
                  service === selectedService ? "selected" : ""
                }`}
                onClick={() => handleMenuClick(service)}
                ref={(el) => (serviceRefs.current[service] = el)}
              >
                {/* <span
                  className={`service_dot ${
                    service === selectedService ? "dot-active" : ""
                  }`}
                ></span> */}
                {/* <div className="service_item_content">{service}</div> */}
                <div className="event_mgmt_our_service_item_content">
                  <span className="event_mgmt_our_service_item_dot">
                    {service === selectedService && (
                      <img src={home_chess} alt="home chess icon" />
                    )}
                  </span>
                  {service}
                </div>
              </div>
            ))}
          </div>

          {/* ICON  */}

          {/* <div className="out_service_button_icon">
            <div
              className="event_mgmt_our_service_left_side_up_button"
              onClick={scrollMenuUp}
            >
              <img src={up_icon} alt="Up_Icon" />
            </div>
            <div
              className="event_mgmt_our_service_left_side_down_button"
              onClick={scrollMenuDown}
            >
              <img src={down_icon} alt="Down_Icon" />
            </div>
          </div> */}
        </div>

        <div className="event_mgmt_our_service_right_side_services">
          <div className="event_mgmt_our_service_right_side_services_title">
            <p>{services[selectedService].title}</p>
            <div className="event_mgmt_our_service_horizontal_line"></div>
          </div>
          <div
            className={`event_mgmt_our_service_right_side_services_content ${
              showContent ? "fade-in" : "fade-out"
            }`}
          >
            {services[selectedService].content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventMgmtOurServiceSection;
