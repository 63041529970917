import React from "react";
import PageAboutExperience from "../../About/AboutExperience/PageAboutExperience";

const IndustriesExcellenceSection = () => {
  const experienceTitle =
    "Feeling the Pulse of the Industry with Our Proficiency";
  const experienceDescription =
    "We understand the need of the industry domains and our experience and expertise, laced with our skilled manpower makes us one of the topmost service providers and a step ahead of the rest.";
  const locationsLabel = "Industries";
  const employeesLabel = "Client Retention";
  const clientsLabel = "Taskforce";
  const locationsCount = "100+";
  const employeesCount = ">95+%";
  const clientsCount = "40000+";
  return (
    <div>
      <PageAboutExperience
        experienceTitle={experienceTitle}
        experienceDescription={experienceDescription}
        locationsLabel={locationsLabel}
        employeesLabel={employeesLabel}
        clientsLabel={clientsLabel}
        locationsCount={locationsCount}
        employeesCount={employeesCount}
        clientsCount={clientsCount}
      />
    </div>
  );
};

export default IndustriesExcellenceSection;
