import React, { useState } from "react";
import "./VideoSection.css";
import "../../../assets/color/root_color.css";
import play_btn from "../../../assets/icons/video_section_play_btn.svg";
import DialogBox from "./DialogBox";
import { Link } from "react-router-dom";

const VideoSection = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => setDialogOpen(false);
  return (
    <>
      <div className="video_section_main_container">
        <div className="video_section_left_side">
          <div className="video_section_left_side_content">
            <h6>LOOK NO FURTHER</h6>
            <h3>Are You Seeking Security, Facility or Fire Services ?</h3>
            <p>
              You are at the right place. Reach out to us and enjoy a wholesome
              experience, leaving all the worry on our shoulder.
            </p>
            <Link to="/contact-us">
              <button type="button">Contact Us</button>
            </Link>
          </div>
        </div>

        <div className="video_section_right_side">
          <div className="video_section_right_side_82">
            <div className="video_section_right_side_bg_color">
              <button onClick={openDialog} className="play-btn">
                <img src={play_btn} alt="Play Button" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isDialogOpen && <DialogBox onClose={closeDialog} />}
    </>
  );
};

export default VideoSection;
