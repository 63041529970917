import React from "react";
import PageAboutExperience from "../../../About/AboutExperience/PageAboutExperience";

const FacilityMgmtServiceExcellenceSection = () => {
  const experienceTitle =
    "Encompassing All Facets of Facility Management Services  ";
  const experienceDescription =
    "Our extensive experience in the facility management domain and relentless pursuit towards quality-driven services have taken us into every nook and corner of India and there is no looking back!";
  const locationsLabel = "Locations";
  const employeesLabel = "Clients";
  const clientsLabel = "Taskforce";
  const locationsCount = "100+";
  const employeesCount = "150 +";
  const clientsCount = "8K +";
  return (
    <div>
      <PageAboutExperience
        experienceTitle={experienceTitle}
        experienceDescription={experienceDescription}
        locationsLabel={locationsLabel}
        employeesLabel={employeesLabel}
        clientsLabel={clientsLabel}
        locationsCount={locationsCount}
        employeesCount={employeesCount}
        clientsCount={clientsCount}
      />
    </div>
  );
};

export default FacilityMgmtServiceExcellenceSection;
